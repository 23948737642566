import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';

const ScrollTo = (arg?: TSiteActionProps['argument']) => {
  if (arg && _.isString(arg)) {
    const blockDiv = document.getElementById(arg);
    if (!blockDiv) {
      console.log(`Item "${arg}" not found!`);
      return;
    }
    blockDiv.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  } else {
    console.log('wrong argument for scrollTo action!');
  }
};

export default ScrollTo;
