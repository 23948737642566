import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { TViewFilter } from './types';
import { TViewSet } from '../SetVewSort/types';
import { store } from '../../../Store/Store';
import SetCustomState from '../SetCustomState';

const SetViewFilter = (arg?: TSiteActionProps['argument']) => {
  if (!arg || !_.isObject(arg) || _.isArray(arg) || !arg.id || !arg.attr) {
    console.log(
      `argument must be object with type { id: string; attr: string; value?: string | number | boolean; type?: 'eq' | 'contains' }`
    );
    return;
  }

  const filter = arg as TViewFilter & { id: string };

  const viewSets =
    (store.getState().sliceUI.customStates.viewSets as
      | Record<string, TViewSet>
      | undefined) || {};

  const constrNewFilters = () => {
    const oldFilters = viewSets?.[filter.id]?.filters || [];
    if (!filter.type || !filter.value) {
      return oldFilters.filter((item) => item.attr !== filter.attr);
    } else {
      return [
        ...oldFilters.filter((item) => item.attr !== filter.attr),
        _.pick(filter, ['attr', 'value', 'type']),
      ];
    }
  };

  const newFilters = constrNewFilters();

  const newViewSets = {
    ...(viewSets || {}),
    [filter.id]: {
      ...(viewSets[filter.id] || {}),
      filters: newFilters,
    },
  };

  SetCustomState({
    name: 'viewSets',
    value: newViewSets,
  });
};

export default SetViewFilter;
