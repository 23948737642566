import Action from '..';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { constructMutatorContexts } from '../../Mutator/Helpers/constructMutatorContexts';
import { Mutator } from '../../Mutator/Mutator';

/**
 * Ассинхронная функция выполнения массива экшенов
 * @param actions массив параметров экшенов
 * @param action экземпляр класса Action
 * @param srcMutator экземпляр класса Mutator
 */
export async function execMultiplyActions(
  actions: TSiteActionProps[],
  srcMutator?: Mutator
) {
  const mutator = srcMutator || new Mutator(constructMutatorContexts());
  for (const props of actions.filter((item) => !item.excluded)) {
    await new Promise((resolve) => {
      if (!Action[props.name]) {
        console.log(`action "${props.name}" not found!`);
        resolve(null);
      } else {
        const mutantProps = mutator.applyObjectVariables(props);
        Action[props.name](mutantProps.argument);
        resolve(null);
      }
    });
  }
}
