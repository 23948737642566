import { call, take } from 'redux-saga/effects';
import { workerGetLocalStorage } from '../Workers/workerGetLocalStorage';
import { GET_INITIAL_REQUEST } from '../../Settings/extraActions';
import { store } from '../Store';
import _ from 'lodash';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { workerGetInitData } from '../Workers/workerGetInitData';
import { workerGetCollection } from '../Workers/workerGetCollection';
import { Mutator } from '../../Library/Mutator/Mutator';
import { constructMutatorContexts } from '../../Library/Mutator/Helpers/constructMutatorContexts';

export function* sagaInitialWatch() {
  while (true) {
    yield take(GET_INITIAL_REQUEST);
    yield call(() => workerGetLocalStorage());

    const UI = store.getState().sliceUI;
    if (UI.jwt) {
      yield call(() =>
        workerGetCollection({
          payload: {
            id: 'me',
            name: 'me',
            force: true,
            onSucceed: {
              name: 'setMe',
              argument: '<%= current.request?.response?.data?.[0]?.id %>',
            },
            onRejected: {
              name: 'logOut',
            },
          },
          type: 'me',
        })
      );
    }

    yield call(() => workerGetInitData());

    //TODO  Доработать после обновления Mutator
    if (UI.jwt) {
      const siteSettings = _.find(
        store.getState().sliceStructure.items.siteSets,
        (item) => item.locale === store.getState().sliceUI.locale
      );
      const propsActions = siteSettings?.loginActions
        ? _.isArray(siteSettings.loginActions)
          ? siteSettings.loginActions
          : [siteSettings.loginActions]
        : [];
      const loginActions = propsActions.filter((item) => !item.excluded);
      if (loginActions.length) {
        const mutator = new Mutator(constructMutatorContexts());
        execMultiplyActions(loginActions, mutator);
      }
    }
  }
}
