/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { NavigateFunction } from 'react-router-dom';
import { store } from '../../../Store/Store';
import { setNavigateTo } from '../../../Store/Slices/UI/sliceUI';
import QueryString, { ParsedQs } from 'qs';

const SetQuery = (arg?: TSiteActionProps['argument']) => {
  if (!arg || !_.isObject(arg)) {
    console.log('Argument must be { [param:string]: string } type');
    return;
  }

  const currentSearch: ParsedQs = QueryString.parse(
    window.location.search.replace(/^\?/, '')
  );

  const newSearchString = `?${QueryString.stringify(
    _.merge(currentSearch, arg)
  )}`;

  const url = window.location.pathname + newSearchString;
  store.dispatch(setNavigateTo({ url }));
};

export default SetQuery;
