import _ from 'lodash';
import { useMutationContexts } from '../../../../../Library/Mutator/Hooks/useMutationContexts';
import { mutantTemplateElement } from '../Helpers/mutantTemplateElement';
import { TTemplateElem } from '../types';
import { Mutator } from '../../../../../Library/Mutator/Mutator';
import { templateElements } from '../Helpers/templateElements';
import { CustomElement } from './CustomElement';
import { DefaultElement } from './DefaultElement';

export const DynamicElement: React.FC<TTemplateElem> = (templateElement) => {
  const mutationContexts = useMutationContexts(templateElement.contexts);
  const mutator = new Mutator(mutationContexts);

  const mutantElement = mutantTemplateElement(
    mutator,
    _.omit(templateElement, 'contexts') as TTemplateElem,
    templateElement.contexts?.template?.path
  );

  if (!mutantElement || mutantElement.excluded) return null;

  const customElement = _.get(templateElements, mutantElement.tag || 'unknown');

  const domElement = customElement ? (
    <CustomElement
      {...{
        component: customElement,
        templateElement: {
          ...mutantElement,
          contexts: templateElement.contexts,
        },
      }}
    />
  ) : (
    <DefaultElement
      {...{ ...mutantElement, contexts: templateElement.contexts }}
    />
  );

  return <>{domElement}</>;
};
