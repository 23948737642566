import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { store } from '../../../Store/Store';
import { TViewSet, TViewSort } from './types';
import SetCustomState from '../SetCustomState';

const SetViewSort = (arg?: TSiteActionProps['argument']) => {
  if (!arg || !_.isObject(arg) || _.isArray(arg) || !arg.id) {
    console.log(
      `argument must be object with type { id: string; attr?: string; dir?: 'asc' | 'desc' }`
    );
    return;
  }
  const sort = arg as TViewSort & { id: string };

  const viewSets =
    (store.getState().sliceUI.customStates.viewSets as
      | Record<string, TViewSet>
      | undefined) || {};

  const newViewSets = {
    ...(viewSets || {}),
    [sort.id]: {
      ...(viewSets[sort.id] || {}),
      ...(!sort.attr || !sort.dir
        ? {}
        : { sort: _.pick(sort, ['attr', 'dir']) }),
    },
  };

  SetCustomState({
    name: 'viewSets',
    value: newViewSets,
  });
};

export default SetViewSort;
