import { call, put } from 'redux-saga/effects';
import {
  authDataSubmit,
  authDataSubmitPending,
  authDataSubmitRejected,
  authDataSubmitSucceed,
} from '../Actions/actionsExtra';
import { store } from '../Store';
import { API_ENDPOINTS, API_URL_PREFIX } from '../../Settings/api';
import {
  TAuthDataSubmit,
  TAuthLoginResponseObject,
  TAuthRegisterResponseObject,
  TAuthResetPasswordResponseObject,
  TAuthResponseObject,
} from '../../Types/typesFetch';
import { fetchApi } from './Helpers/fetchApi';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import _ from 'lodash';
import { normalize } from 'normalizr';
import schemaNormalizeUsers from '../Normalizers/schemaNormalizeUsers';
import { TSliceContent } from '../Slices/Content/sliceContent';
import { constructMutatorContexts } from '../../Library/Mutator/Helpers/constructMutatorContexts';
import { Mutator } from '../../Library/Mutator/Mutator';

export function* workerAuthSubmit({
  payload,
}: ReturnType<typeof authDataSubmit>) {
  const {
    type,
    email,
    identifier,
    username,
    password,
    passwordConfirmation,
    code,
    onRejected,
    onSucceed,
  } = payload;
  const rootState = store.getState();
  const status = rootState.sliceUI.auth[payload.type]?.status || 'initial';

  if (status === 'progress' || status === 'success') return;

  yield put(authDataSubmitPending({ type: payload.type }));

  const url = API_URL_PREFIX + API_ENDPOINTS[type];

  const body: Record<TAuthDataSubmit['type'], Omit<TAuthDataSubmit, 'type'>> = {
    login: { identifier, password },
    register: {
      email,
      username,
      password,
    },
    'forgot-password': { email },
    'reset-password': {
      code,
      password,
      passwordConfirmation,
    },
  };

  const responseObject: TAuthResponseObject = yield call(() => {
    return fetchApi({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body[type],
    });
  });

  const context = constructMutatorContexts();
  const mutator = new Mutator({
    ...context,
    current: { request: { response: responseObject } },
  });

  if (responseObject.error) {
    yield put(authDataSubmitRejected({ type, error: responseObject.error }));
    const payloadErrorActions = onRejected
      ? _.isArray(onRejected)
        ? onRejected
        : [onRejected]
      : [];

    execMultiplyActions(payloadErrorActions, mutator);
  } else {
    switch (type) {
      case 'login':
        yield put(
          authDataSubmitSucceed({
            type,
            jwt: (responseObject as TAuthLoginResponseObject).jwt,
            items: normalize(
              (responseObject as TAuthLoginResponseObject).user,
              schemaNormalizeUsers()
            ).entities as TSliceContent['items'],
          })
        );
        break;
      case 'register':
        yield put(
          authDataSubmitSucceed({
            type,
            items: normalize(
              (responseObject as TAuthRegisterResponseObject).user,
              schemaNormalizeUsers()
            ).entities as TSliceContent['items'],
          })
        );
        break;
      case 'forgot-password':
        yield put(
          authDataSubmitSucceed({
            type,
          })
        );
        break;
      case 'reset-password':
        yield put(
          authDataSubmitSucceed({
            type,
            items: normalize(
              (responseObject as TAuthResetPasswordResponseObject).user,
              schemaNormalizeUsers()
            ).entities as TSliceContent['items'],
          })
        );
        break;
    }

    yield call(() => {
      const payloadSucceedActions = onSucceed
        ? _.isArray(onSucceed)
          ? onSucceed
          : [onSucceed]
        : [];

      execMultiplyActions(payloadSucceedActions, mutator);
    });
  }
}
