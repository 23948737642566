import _ from 'lodash';
import { TCustomTemplate } from './types';
import { useTemplate } from './helpers/useTemplate';
import { TMutationContext } from '../../../../Library/Mutator/types';
import { TemplateElements } from '../TemplateElement/Components/TemplateElements';
import { updateElemVariables } from './helpers/updateElemVariables';

/**
 * Компонент макета с дочерними элементами
 *
 * @param templateElement - объект макета
 * @returns - React-компоненты дочерних элементов
 */
export const CustomTemplate: React.FC<TCustomTemplate> = ({
  id,
  name,
  variables,
  variablesDepth,
  contexts,
  childrenPrototype,
}) => {
  const template = useTemplate(id, name);

  if (!template || template.excluded) return null;

  const templateContext = contexts?.template;

  const children = childrenPrototype
    ? _.isArray(childrenPrototype)
      ? childrenPrototype
      : [childrenPrototype]
    : [];

  const newVariablesData = updateElemVariables(
    { variables, variablesDepth },
    {
      variables: templateContext?.variables,
      variablesDepth: templateContext?.variablesDepth,
    }
  );

  const newContexts: TMutationContext['current'] = {
    ...contexts,
    template: {
      id,
      name,
      path: [...(templateContext?.path || []), id || name || 'unknown'],
      variables: newVariablesData.variables,
      variablesDepth: newVariablesData.variablesDepth,
      children,
    },
  };

  return (
    <TemplateElements elements={template.template} contexts={newContexts} />
  );
};
