import _ from 'lodash';
import { TTemplateElem } from '../types';
import { templateElements } from '../Helpers/templateElements';
import { CustomElement } from './CustomElement';
import { DefaultElement } from './DefaultElement';
import { TMutationContext } from '../../../../../Library/Mutator/types';
import { applyHandlers } from '../Helpers/applyHandlers';

export const StaticElement: React.FC<TTemplateElem> = (templateElement) => {
  if (templateElement.excluded) return null;
  const withHandlers = Object.keys(templateElement).find((key) =>
    /^on[A-Z].+/.test(key)
  )
    ? (applyHandlers(templateElement) as TTemplateElem)
    : templateElement;

  // const element = _.omit(templateElement, ['contexts']);
  // const paramsString = JSON.stringify(
  //   element.tag === 'Template' ? element : _.omit(element, ['children'])
  // );
  // const hasMutations = /<%=?.*%>/gm.test(paramsString);
  // if (hasMutations) console.log(element);

  const customElement = _.get(templateElements, withHandlers.tag || 'unknown');

  const domElement = customElement ? (
    <CustomElement
      {...{
        component: customElement,
        templateElement: withHandlers as TTemplateElem & {
          contexts: Partial<TMutationContext>;
        },
      }}
    />
  ) : (
    <DefaultElement {...withHandlers} />
  );

  return <>{domElement}</>;
};
