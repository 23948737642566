import _ from 'lodash';
import { Location, Params } from 'react-router-dom';
import { TSliceUI } from '../../../Store/Slices/UI/sliceUI';
import { TUser } from '../../../Types/typesUser';
import { TMutationContext } from '../types';
import { TBaseDataObject, TToast } from '../../../Types/typesGlobal';
import { TCollection } from '../../../Store/Slices/types';
import { store } from '../../../Store/Store';
import { constructUrlParams } from './constructUrlParams';
import { getMyUser } from '../../../Store/Slices/Content/getMyUser';
import { getSiteSettings } from '../../../Store/Slices/Structure/getSiteSettings';
import { API_URL_PREFIX } from '../../../Settings/api';
import QueryString from 'qs';
import Action from '../../Action';

export const constructMutatorContexts = (
  selectorLocation?: Location,
  selectorParams?: Readonly<Params<string>>,
  selectorLocale?: string,
  selectorStates?: TSliceUI['customStates'],
  selectorToken?: string,
  selectorUser?: TUser,
  current?: TMutationContext['current'] | undefined,
  selectorVariables?: TBaseDataObject | undefined,
  selectorToasts?: (TToast & {
    show?: boolean;
  })[],
  collections?: Record<string, TCollection>
) => {
  const location = selectorLocation || window.location;
  const query = QueryString.parse(location.search.replace(/^\?/, ''));
  const params =
    selectorParams ||
    constructUrlParams(
      location.pathname,
      _.map(store.getState().sliceStructure.items.sitePages, (page) => page.url)
    );
  const locale = selectorLocale || store.getState().sliceUI.locale;
  const states = selectorStates || store.getState().sliceUI.customStates;
  const meID = store.getState().sliceUI.meID;
  const user =
    selectorUser ||
    (meID
      ? getMyUser(
          store.getState().sliceContent.items.users[meID],
          store.getState().sliceContent.items
        )
      : undefined) ||
    {};
  const token = selectorToken || store.getState().sliceUI.jwt;
  const variables =
    selectorVariables ||
    getSiteSettings(
      store.getState().sliceStructure.items.siteSets,
      store.getState().sliceStructure.items.alerts,
      store.getState().sliceUI.locale
    )?.globalVariables;

  const toasts =
    selectorToasts || Object.values(store.getState().sliceUI.toasts);

  return {
    ui: {
      locale,
      states,
    },
    me: {
      user,
      token,
    },
    current: {
      url: {
        pathName: location.pathname,
        params: params || {},
        query: query || {},
        prefix: API_URL_PREFIX,
      },
      ...(current || {}),
    },
    global: {
      variables: variables || {},
      toasts,
      collections,
    },
    fn: {
      QueryString,
    },
    action: Action,
  };
};
