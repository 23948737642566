import _ from 'lodash';
import { setJWT } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';
import { TSiteActionProps } from '../../../Types/typesGlobal';

const SetToken = (arg: TSiteActionProps['argument']) => {
  if (!arg || !_.isString(arg)) {
    console.log('argument must be defined and be a string!');
    return;
  }
  store.dispatch(setJWT(arg));
};

export default SetToken;
