import {
  TCustomForm,
  TCustomFormContext,
  TFormInputContext,
  TFormInputFile,
  TFormInputImage,
  TFormInputSimple,
} from './types';
import { useDispatch, useSelector } from 'react-redux';
import { FormEventHandler, useEffect, useState } from 'react';
import _ from 'lodash';
import { validateForm } from './Helpers/validateForm';
import { Mutator } from '../../../../Library/Mutator/Mutator';
import { TemplateElements } from '../TemplateElement/Components/TemplateElements';
import { selectAuthStates } from '../../../../Store/Slices/UI/selectors';
import { selectItemSubmits } from '../../../../Store/Slices/Content/selectors';
import {
  authDataSubmit,
  collectionItemSubmit,
} from '../../../../Store/Actions/actionsExtra';
import { TBaseDataObject } from '../../../../Types/typesGlobal';
import { TAuthDataSubmit } from '../../../../Types/typesFetch';
import { selectItemTypes } from '../../../../Store/Slices/Structure/selectors';
import { AUTH_FORM_TYPES } from './Helpers/constants';
import { RootState } from '../../../../Store/Store';
import { useMutationContexts } from '../../../../Library/Mutator/Hooks/useMutationContexts';
import { constructFormState } from './Helpers/constructFormState';

/**
 * Компонент для отправки формы и получения ответа от сервера
 * @param formElement {TCustomForm}
 * @param formElement.query {TApiQuery} - параметры запроса
 * @param formElement.query.url {string} - URL запроса
 * @param formElement.query.body {object} - тело запроса
 * @param formElement.query.responseModel {string} - модель ответа от сервера
 * @param formElement.query.id {string} - идентификатор запроса
 * @param formElement.mutationProps {object} - настройки мутатора
 * @param formElement.mutationProps.attrExclude {Array<string>} - атрибуты для исключения
 * @param formElement.mutationProps.attrInclude {Array<string>} - атрибуты для включения
 * @param formElement.mutationProps.valExclude {Array<string>} - значения для исключения
 * @param formElement.mutationProps.valInclude {Array<string>} - значения для включения
 * @param formElement.test {boolean} - флаг тестового режима
 */
export const CustomForm: React.FC<TCustomForm> = (formElement) => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthStates)[formElement.id];
  const itemSubmitState = useSelector(selectItemSubmits)[formElement.id];
  const authType = AUTH_FORM_TYPES.find(
    (item) => item.name === formElement.type
  );
  const contentItemType = useSelector((state: RootState) =>
    _.find(Object.values(selectItemTypes(state)), { name: formElement.type })
  );

  const requestStatus = authType
    ? authState?.status || 'initial'
    : itemSubmitState?.status || 'initial';

  const itemType = authType || contentItemType;

  const [inputs, setInputs] = useState<Record<string, TFormInputContext>>(
    itemType?.attributes
      ? constructFormState(itemType.attributes, formElement.sourceItem)
      : {}
  );

  useEffect(() => {
    const newInputs = itemType?.attributes
      ? constructFormState(itemType.attributes, formElement.sourceItem)
      : {};
    setInputs(newInputs);
  }, [formElement.sourceItem, itemType?.attributes]);

  if (!formElement.id || !formElement.type) {
    console.log(`Required attributes are missing: id, type`);
  }

  const inputDisabled = requestStatus === 'progress';
  const submitDisabled =
    requestStatus === 'progress' ||
    !_.findKey(inputs, { touched: true }) ||
    !!_.findKey(
      inputs,
      (item) => item.required && (item.value === undefined || item.value === '')
    ) ||
    !!_.findKey(inputs, (item) => item.errors?.length);

  const formContextData: Omit<
    TCustomFormContext,
    'handleSubmit' | 'handleChange'
  > = {
    inputDisabled,
    submitDisabled,
    requestStatus,
    inputs,
  };
  const mutationContexts = useMutationContexts(formElement.contexts);
  const mutator = new Mutator(mutationContexts);
  mutator.addContext({
    current: { form: formContextData as TCustomFormContext },
  });

  const handleChange = (
    input: TFormInputSimple | TFormInputImage | TFormInputFile
  ) => {
    const newInput: TFormInputContext = {
      ...input,
      touched: true,
      errors: [],
    };
    setInputs({
      ...inputs,
      [input.name]: newInput,
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const validatedInputs = validateForm(mutator, inputs);
    setInputs(validatedInputs);

    if (
      _.flatten(
        _.compact(Object.values(validatedInputs).map((input) => input.errors))
      ).length
    )
      return;

    const attributes = mutator.applyObjectVariables(formElement.attributes);
    const formSubmitObj: TBaseDataObject = {
      ...(formElement.sourceItem ? { id: formElement.sourceItem.id } : {}),
      ..._.reduce(
        itemType?.attributes,
        (r, v) => {
          const name = v.name;
          const type = v.type;
          const newValue =
            type === 'attributes' ? attributes?.[name] : inputs[name]?.value;

          if (!newValue) return { ...r };
          return { ...r, [v.name]: newValue };
        },
        {}
      ),
    };

    if (formElement.test) {
      console.log({
        id: formElement.id,
        type: formElement.type,
        object: formSubmitObj,
      });
    } else {
      if (authType) {
        dispatch(
          authDataSubmit({
            type: formElement.type as TAuthDataSubmit['type'],
            ...formSubmitObj,
            ...formElement.actions,
          })
        );
      }
      if (contentItemType) {
        dispatch(
          collectionItemSubmit({
            id: formElement.id,
            collectionItem: formSubmitObj,
            type: formElement.type,
            onSucceed: formElement.actions?.onSucceed,
            onRejected: formElement.actions?.onRejected,
          })
        );
      }
    }
  };

  const { childrenPrototype, contexts } = formElement;

  const newContexts = {
    ...contexts,
    form: {
      ...formContextData,
      handleSubmit,
      handleChange,
    },
  };

  return (
    <TemplateElements elements={childrenPrototype} contexts={newContexts} />
  );
};
