import React from 'react';
import { TTemplateElem } from '../types';
import _ from 'lodash';
import { TemplateElement } from '../TemplateElement';

export const Children: React.FC<TTemplateElem> = ({ children, contexts }) => {
  return (
    <>
      {_.isArray(children) ? (
        children.map((child, i) =>
          _.isObject(child) ? (
            <TemplateElement
              key={i}
              {...{
                ...child,
                contexts,
              }}
            />
          ) : (
            _.toString(child)
          )
        )
      ) : _.isObject(children) ? (
        <TemplateElement
          key={0}
          {...{
            ...children,
            contexts,
          }}
        />
      ) : (
        _.toString(children)
      )}
    </>
  );
};
