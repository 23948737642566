import { TTemplateElem } from './types';
import React from 'react';
import { DynamicElement } from './Components/DynamicElement';
import { StaticElement } from './Components/StaticElement';

export const TemplateElement: React.FC<TTemplateElem> = (templateElement) => {
  // if (templateElement.label === 'mutation-test-2') console.log(templateElement);

  const element = templateElement.mutationSchema?.hasMutations ? (
    <DynamicElement {...templateElement} />
  ) : (
    <StaticElement {...templateElement} />
  );

  return <>{element}</>;

  return <DynamicElement {...templateElement} />;
};
