import DeleteToast from './DeleteToast';
import GetCollection from './GetCollection';
import LogOut from './LogOut';
import NavigateBack from './NavigateBack';
import NavigateTo from './NavigateTo';
import RedirectTo from './RedirectTo';
import ScrollTo from './ScrollTo';
import SetCustomState from './SetCustomState';
import SetItem from './SetItem';
import SetMe from './SetMe';
import SetQuery from './SetQuery';
import SetToken from './SetToken';
import SetViewSort from './SetVewSort';
import SetViewFilter from './SetViewFilter';
import ShowToast from './ShowToast';
import SwitchBooleanCustomState from './SwitchBooleanCustomState';
import Throw from './Throw';

const Action: Record<string, (a: any) => void> = {
  deleteToast: DeleteToast,
  getCollection: GetCollection,
  logOut: LogOut,
  navigateBack: NavigateBack,
  navigateTo: NavigateTo,
  redirectTo: RedirectTo,
  scrollTo: ScrollTo,
  setUIState: SetCustomState,
  setItem: SetItem,
  setMe: SetMe,
  setQuery: SetQuery,
  setToken: SetToken,
  showToast: ShowToast,
  switchBooleanUIState: SwitchBooleanCustomState,
  throw: Throw,
  setViewSort: SetViewSort,
  setViewFilter: SetViewFilter,
};

export default Action;
