import _ from 'lodash';
import { TTemplateElem } from '../types';
import { ReactEventHandler } from 'react';
import { execMultiplyActions } from '../../../../../Library/Action/Helpers/execMultiplyActions';
import { TSiteActionProps } from '../../../../../Types/typesGlobal';

export const applyHandlers = (element: TTemplateElem) => {
  return _.reduce(
    element,
    (r, v, k) => {
      if (/^on[A-Z].+/.test(k) && k !== 'onLoad') {
        const handler: ReactEventHandler<HTMLElement> = (e) => {
          if (e) e.preventDefault();
          if (!v) return;
          if (_.isArray(v)) {
            execMultiplyActions(v as TSiteActionProps[]);
          } else {
            execMultiplyActions([v as TSiteActionProps]);
          }
        };
        return { ...r, [k]: _.isFunction(v) ? v : handler } as TTemplateElem;
      }
      return { ...r, [k]: v };
    },
    {}
  );
};
