import { call, put } from 'redux-saga/effects';
import { store } from '../Store';
import {
  getInitialRequestPending,
  getInitialRequestRejected,
  getInitialRequestSucceed,
} from '../Actions/actionsExtra';
import { fetchApi } from './Helpers/fetchApi';
import { API_ENDPOINTS, API_URL_PREFIX } from '../../Settings/api';
import { TResponseObject } from '../../Types/typesFetch';
import { TSliceStructure } from '../Slices/Structure/sliceStructure';
import _ from 'lodash';
import ContextSelectors from './Helpers/ContextSelectors';

export function* workerGetInitData() {
  const status =
    store.getState().sliceStructure.collections.init?.fetchStatus || 'initial';
  if (status !== 'initial') return;
  yield put(getInitialRequestPending());
  const responseObject: TResponseObject = yield call(() =>
    fetchApi({
      url: API_URL_PREFIX + API_ENDPOINTS.init,
      method: 'GET',
      parameters: {
        locale: store.getState().sliceUI.locale,
      },
    })
  );

  if (responseObject.error) {
    yield put(getInitialRequestRejected(responseObject.error));
  } else {
    const data = responseObject.data as Partial<TSliceStructure['items']>;
    const selectors = new ContextSelectors();
    yield put(
      getInitialRequestSucceed({
        ...data,
        siteSets: _.reduce(
          data.siteSets,
          (r, v, k) => ({
            ...r,
            [k]: {
              ...v,
              headElements: v.headElements?.map((element) =>
                selectors.element(element)
              ),
            },
          }),
          {}
        ),
        sitePages: _.reduce(
          data.sitePages,
          (r, v, k) => ({
            ...r,
            [k]: {
              ...v,
              headElements: v.headElements?.map((element) =>
                selectors.element(element)
              ),
              template: _.toString(v.template.id),
            },
          }),
          {}
        ),
        itemTemplates: _.reduce(
          data.itemTemplates,
          (r, v, k) => ({ ...r, [k]: selectors.template(v) }),
          {}
        ),
        alerts: _.reduce(
          data.alerts,
          (r, v, k) => ({
            ...r,
            [k]: {
              ...v,
              values: _.isArray(v.values)
                ? v.values?.map((element) => selectors.element(element))
                : selectors.element(v.values),
            },
          }),
          {}
        ),
      })
    );
  }
}
