import { call, put } from 'redux-saga/effects';
import {
  collectionItemSubmit,
  collectionItemSubmitPending,
  collectionItemSubmitRejected,
  collectionItemSubmitSucceed,
} from '../Actions/actionsExtra';
import { store } from '../Store';
import { fetchApi } from './Helpers/fetchApi';
import { TResponseObject } from '../../Types/typesFetch';
import {
  API_ENDPOINTS,
  API_URL_PREFIX,
  fetchErrorActions,
} from '../../Settings/api';
import _ from 'lodash';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { TSliceContent } from '../Slices/Content/sliceContent';
import { schemaNormalize } from '../Normalizers/schemaNormalize';
import { normalize } from 'normalizr';
import { constructMutatorContexts } from '../../Library/Mutator/Helpers/constructMutatorContexts';
import { Mutator } from '../../Library/Mutator/Mutator';
import { TAbstractStoreObject } from '../../Types/typesGlobal';

export function* workerCollectionItemSubmit({
  payload,
}: ReturnType<typeof collectionItemSubmit>) {
  const { id, collectionItem, type, onSucceed, onRejected } = payload;
  const rootState = store.getState();
  const status = rootState.sliceContent.itemSubmits[id]?.status || 'initial';
  if (status === 'progress') return;

  yield put(collectionItemSubmitPending({ id }));

  const itemType = /^\d+$/.test(type)
    ? Object.values(store.getState().sliceStructure.items.itemTypes).find(
        (item) => item.id == type
      )?.name || 'unknown'
    : type;

  const responseObject: TResponseObject = yield call(() => {
    return fetchApi({
      url:
        API_URL_PREFIX +
        API_ENDPOINTS.items +
        '/' +
        itemType +
        (collectionItem.id ? `/${collectionItem.id}` : ''),
      method: collectionItem.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      parameters: {
        locale: rootState.sliceUI.locale,
      },
      body: collectionItem,
    });
  });

  const context = constructMutatorContexts();
  const mutator = new Mutator({
    ...context,
    current: { request: { response: responseObject } },
  });

  if (
    responseObject.error ||
    !responseObject.data ||
    !responseObject.meta?.type
  ) {
    const error: TResponseObject['error'] = responseObject.error
      ? responseObject.error
      : !responseObject.data
      ? { status: 400, name: 'ApplicationError', message: 'Data not found' }
      : { status: 400, name: 'ValidationError', message: 'Unknown data type' };

    yield put(collectionItemSubmitRejected({ id, error }));

    const defaultErrorActions =
      fetchErrorActions.find(
        (item) =>
          item.status === responseObject.error?.status &&
          item.message === responseObject.error?.message
      )?.actions || [];

    const payloadErrorActions = onRejected
      ? _.isArray(onRejected)
        ? onRejected
        : [onRejected]
      : [];

    execMultiplyActions(
      [...defaultErrorActions, ...payloadErrorActions],
      mutator
    );
  } else {
    const responseData = responseObject.data;
    const dataArray = _.isArray(responseData) ? responseData : [responseData];
    const dataType = responseObject.meta.type as keyof TSliceContent['items'];

    const normSchema = schemaNormalize(dataType);
    const itemsCollection = normSchema
      ? normalize(
          {
            [dataType]: dataArray,
          },
          normSchema()
        ).entities
      : ({
          dataType: _.reduce(
            dataArray as unknown as TAbstractStoreObject[],
            (r, v) => ({ ...r, [v.id]: v }),
            {}
          ),
        } as Partial<TSliceContent['items']>);

    yield put(
      collectionItemSubmitSucceed({
        id,
        itemsType: dataType,
        items: itemsCollection as Partial<TSliceContent['items']>,
      })
    );

    yield call(() => {
      const payloadSucceedActions = onSucceed
        ? _.isArray(onSucceed)
          ? onSucceed
          : [onSucceed]
        : [];

      execMultiplyActions(payloadSucceedActions, mutator);
    });
  }
}
