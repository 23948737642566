import _ from 'lodash';
import { TUser } from '../../../../../Types/typesUser';
import { TItemAttribute, TUnit } from '../../../../../Types/typesUnit';

export const constructFormState = (
  attributes: TItemAttribute[],
  sourceItem?: TUser | TUnit
) => {
  return _.reduce(
    attributes,
    (r, v) => {
      const initValuePath =
        v.type === 'fieldsImage' ? `${v.name}.orig` : v.name;
      const initValue = _.get(sourceItem, initValuePath);
      return {
        ...r,
        [v.name]: { ...v, value: initValue },
      };
    },
    {}
  );
};
