export const SELECTORS = {
  location: [
    /current\??\.url\??\.params/gm,
    /current\??\.url\??\.query/gm,
    /current\??\.url\??\.pathname/gm,
  ],
  locale: [/ui\??\.locale/gm],
  states: [/ui\??\.states/gm],
  myUser: [/me\??\.user/gm],
  token: [/me\??\.token/gm],
  contentItems: [/me\??\.user/gm, /global\??\.collections/gm],
  variables: [/global\??\.variables/gm],
  toastsNorm: [/global\??\.toasts/gm],
  normCollections: [/global\??\.collections/gm],
  page: [/current\??\.page/gm],
  template: [/current\??\.template/gm],
  form: [/current\??\.form/gm],
  input: [/current\??\.input/gm],
  collection: [/current\??\.collection/gm],
  constants: [/current\??\.url\??\.prefix/gm],
};
